footer {
	width: 100%;
	position: relative;
	z-index: 1;
	padding: 0 0 25px;
	background-color: #fff;
	// box-shadow: 0 -5px 45px 0 rgba(0, 0, 0, 0.1);
	@include respond-to('1100') {
		padding: 0 0 15px;
	}
	@include respond-to('extra-small') {
		padding: 0 0 15px;
	}
}

.ftr-wrap {
	margin-top: 45px;
	@include respond-to('small') {
		margin-top: 30px;
	}
	& .section-limit {
		position: relative;
	}
}

.logo__ftr {
	text-align: center;
	& p {
		display: inline-block;
		@include respond-to('small') {
			padding-top: 10px;
		}
	}
	& a {
		display: inline-block;
		@include respond-to('small') {
			margin-#{$left}: 15px !important;
			margin-#{$right}: 15px !important;
		}
		& img {
			// max-width: 200px;
			@include respond-to('small') {
				// max-width: 120px;
			}
		}
	}
}

.site-footer__credit-holder {
	position: absolute;
	#{$left}: 15px;
	top: 50%;
	@include respond-to('medium') {
		position: static;
	}
}