.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}

.header-desktop {
	// height: 100px;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	height: 133px;
	padding-top: 10px;
	background-color: #fff;
	text-align: center;
	transition: height 0.2s ease, padding 0.2s ease;
	& a {
		& img {
			max-width: 183px;
			transition: max-width 0.2s ease;
		}
	}
}

.main-navi {
	display: block;
	padding: 10px 0 0;
	text-align: center;
	background-color: $title;
	background-image: $grad;
	font-size: 0;
	transition: padding 0.2s ease;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
		& > li {
			float: $right;
			margin-#{$left}: 1px;
			position: relative;
			&:last-child {
				margin-#{$left}: 0px;
				& a {
					&::after {
						display: none;
					}
				}
			}
			& > a {
				position: relative;
				font-size: 20px;
				font-weight: 500;
				color: #fff;
				padding: 2px 18px;
				display: block;
				&::after {
					content: '';
					position: absolute;
					#{$left}: -1px;
					top: 50%;
					transform: translateY(-50%);
					height: 80%;
					width: 1px;
					background-color: #fff;
				}
				&:hover {
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {}
      }
      &:hover{
        & > ul{
          visibility: visible;
          opacity: 1;
        }
      }
      padding-bottom: 11px;
      & > ul{/* level 2 */
        display: block;
        padding: 0; 
        margin: 0;
        list-style: none;
        position: absolute;
        top: 42px;
        #{$right}: 0;
        background-color: #2f95c7;
        transition: all 0.4s;
        visibility: hidden;
        opacity: 0;
        & > li{
          &:not(:last-child){
            border-bottom: 1px solid #277ca7;
          }
          & > a{
            font-size: 18px;
            color: #fff;
            display: block;
            position: relative;
            white-space: nowrap;
            padding: 7px 30px;
            transition: all 0.3s;
            &:hover{
              background-color: #3ba8df;
              text-decoration: none;
              color: #fff;
            }
          }
          &:hover{
            & > ul{
              display: block;
            }
          }
          & > ul{/* level 3 */
            display: none;
            padding: 0;
            margin: 0;
            list-style: none;
            position: absolute;
            top: 0;
            #{$right}: 100%; 
            background-color: #2f95c7;
            transition: all 0.2s;
            & > li{
              &:not(:last-child){
                border-bottom: 1px solid rgb(43, 63, 133);
              }
              & > a{ 
                font-size: 18px;
                color: #fff;
                display: block;
                position: relative;
                white-space: nowrap;
                padding: 7px 30px;
                transition: all 0.3s;
                &:hover{
                  
                  background-color: #3ba8df;
                  text-decoration: none;
                  color: #fff;
                }
              }
            }
          }/* END level 3 */
        }
        
      }/* END level 2 */
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			width: 100px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	.header-desktop {}
	.main-navi {
		// padding: 5px 0;
		& > ul {}
	}
	.logo {
		height: 90px;
		padding-top: 7px;
		& img {
			max-width: 128px;
		}
	}
}