.wrap-content {
	padding: 60px 0;
	@include respond-to('small') {
		padding: 40px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
		li{
		}
	}
}

.title-block {
	position: relative;
	text-align: center;
	font-size: 0;
	&_mb {
		margin-bottom: 60px;
		@include respond-to('large') {
			margin-bottom: 40px;
		}
		@include respond-to('small') {
			margin-bottom: 30px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 25px;
		}
	}
	& .title {
		display: inline-block;
		vertical-align: middle;
		max-width: 26%;
		width: 100%;
		@include respond-to('large') {
			max-width: 30%;
		}
		@include respond-to('small') {
			max-width: 50%;
		}
		@include respond-to('extra-small') {
			max-width: 100%;
		}
	}
	& .decor-line {
		display: inline-block;
		vertical-align: middle;
		max-width: 37%;
		width: 100%;
		@include respond-to('large') {
			max-width: 35%;
		}
		@include respond-to('small') {
			max-width: 25%;
		}
		@include respond-to('extra-small') {
			display: none;
		}
		&__left {}
		&__right {}
		& img {
			height: 1px;
			max-width: 100%;
		}
	}
}

.title {
	margin: 0;
	color: $text;
	font-size: 40px;
	font-weight: 700;
	line-height: 0.9;
	text-align: center;
	@include respond-to('medium') {
		font-size: 35px;
	}
	@include respond-to('extra-small') {
		font-size: 30px;
	}
}

.wrap-all-content-not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 18px;
		line-height: 1.2;
		font-weight: 700;
		color: #000;
		margin: 20px 0 20px;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	a {
		font-weight: 700;
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
	& ul {
		list-style: none;
		padding: 0;
		li {
			padding-#{$right}: 25px;
			position: relative;
			font-size: 18px;
			font-weight: 400;
			color: #000;
			@include respond-to('extra-small') {
				font-size: 16px;
			}
			&::after {
				content: '';
				position: absolute;
				#{$right}: 0;
				top: 10px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #000;
			}
		}
	}
}