.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;

}

.hp-articles-sec {
	padding: 65px 0;
	@include respond-to('medium') {
		padding: 35px 0;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
	@include respond-to('extra-small') {
		padding: 15px 0;
	}
}

.hp-articles {
	margin: 0 -22px;
	font-size: 0;
	text-align: center;
	@include respond-to('large') {
		margin: 0 -11px;
	}
	&__item {
		display: inline-block;
		width: 33.33%;
		height: 508px;
		padding: 40px 22px;
		font-size: initial;
		@include respond-to('large') {
			padding: 22px 11px;
			height: 472px;
		}
		@include respond-to('1100') {
			width: 45%;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
}

.hp-art-box {
	display: block;
	position: relative;
	height: 100%;
	max-width: 372px;
	width: 100%;
	margin: 0 auto;
	@include link_no-hover(#000);
	box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
	transition: box-shadow .2s ease, transform .2s ease;
	&:hover,
	&:focus,
	&:active {
		transform: scale(1.03);
	}
	&__img {
		position: relative;
		height: 256px;
		overflow: hidden;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		@include respond-to('large') {
			// height: 240px;
		}
		& span {
			position: absolute;
			bottom: 30px;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			padding: 0 15px;
			color: #fff;
			font-weight: 600;
			font-size: 18px;
			text-align: center;
		}
	}
	&__desc {
		position: relative;
		height: 172px;
		width: 100%;
		padding: 0px 13px 15px;
		overflow: hidden;
		background-color: $accent;
		@include respond-to('large') {}
	}
	&__border {
		position: absolute;
		left: 13px;
		right: 13px;
		top: 15px;
		bottom: 15px;
		width: calc(100%-26px);
		height: calc(100%-30px);
		border: 2px solid #fff;
		pointer-events: none;
	}
	&__button {
		position: absolute;
		bottom: -14px;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 50px;
		width: 200px;
		padding: 10px 5px;
		border: 2px solid $accent;
		text-align: center;
		font-size: 18px;
		font-weight: 900;
		color: $title;
		background: #fff;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: $accent;
		}
		&:active {
			@include btn-effect-active;
		}
	}
}

.hp-art-box-info {
	height: 100%;
	padding-top: 15px;
	background: #fff;
	text-align: center;
	&__title {
		margin-bottom: 15px;
		font-size: 25px;
		font-weight: 900;
		color: $title;
		line-height: 0.9;
		max-height: 48px;
		overflow: hidden;
		@include respond-to('medium') {
			font-size: 22px;
		}
		@include respond-to('mobile-m') {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
	&__desc {
		max-width: 285px;
		margin: 0 auto;
		p {
			margin: 0;
			color: $title;
			font-size: 18px;
			font-weight: 300;
			line-height: 0.9;
		}
	}
}