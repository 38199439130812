.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 47px 0 37px;
	background-image: $grad;
	box-shadow: 0 0 35px 0 rgba($color: #000000, $alpha: 0.2);
	@include respond-to('huge') {
		padding-top: 30px;
	}
	@include respond-to('1100') {
		padding: 30px 0 35px;
	}
}

.form-horizontal {
	max-width: 1330px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		max-width: 250px;
		text-align: $right;
		font-size: 18px;
		font-weight: 400;
		color: #fff;
		line-height: 1;
		@include respond-to('huge') {
			text-align: center;
			margin: 0 auto;
		}
		& span {
			display: block;
			font-size: 25px;
			font-weight: 900;
		}
	}
	& .contact-wrap-input {
		@include respond-to('huge') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 10px;
		max-width: 200px;
		width: 100%;
		@include respond-to('huge') {
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
			max-width: 255px;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
		}
		&_title {
			max-width: 255px;
			width: 100%;
			@include respond-to('huge') {
				display: block;
				margin: 0 auto 10px;
				max-width: 265px;
				float: none;
			}
			@include respond-to('1100') {
				display: block;
				margin: 0 auto 10px;
				max-width: 265px;
			}
		}
		&_ml {
			margin-#{$left}: 0;
		}
		&_button {
			max-width: 180px;
			float: $left;
			margin: 0;
			@include respond-to('huge') {
				display: block;
				margin: 0 auto;
				max-width: 255px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				min-width: 255px;
			}
		}
	}
	& .newslatter-input {
		height: 45px;
		width: 100%;
		padding: 11px 10px 10px;
		background-color: rgba($color: #fff, $alpha: 1);
		color: #383838;
		font-weight: 400;
		font-size: 20px;
		box-shadow: none;
		border: none;
		border-radius: 0;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			transition: opacity 0.2s ease;
			opacity: 1;
			font-size: 20px;
			font-weight: 400;
			color: #383838;
		}
		&:focus {
			&::placeholder {
				opacity: 0;
			}
		}
	}
	& .newslatter-btn {
		position: relative;
		width: 100%;
		margin: 0;
		height: 45px;
		font-size: 18px;
		font-weight: 900;
		background-color: #034B7D;
		border: none;
		color: #fff;
		z-index: 1;
		&:active {
			@include btn-effect-active;
		}
	}
	.help-block {
		font-size: 16px;
		color: #fff !important;
	}

	.msg-success {
		color: #fff;
	}
}


.form-control:focus {
	border-radius: 0;
}

.select-cats {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 20px;
	font-weight: 400;
	color: #383838;
	margin-bottom: 8px;
}