.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	@include respond-to('medium') {
		max-height: 66.66vh;
	}
	@include respond-to('small') {
		// height: 100vh;
		// max-height: 66.66vh;
	}
	@include respond-to('large') {
		// height: 450px;
	}
	@include respond-to('extra-small') {
		// height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('medium') {
		min-height: 66.66vh;
	}
	@include respond-to('small') {
		// min-height: 100vh;
	}
	@include respond-to('large') {
		// min-height: 450px;
	}
	@include respond-to('extra-small') {
		// min-height: 300px;
	}
	&__shadow {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/banner-shadow.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

.banner-text {
	position: absolute;
	left: 50%;
	top: 50%;
	max-width: 1230px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	transform: translate(-50%, -50%);
	line-height: 0.9;
	@include respond-to('small') {
		top: 65%;
	}
	&__title {
		h1{
			text-align: $right;
			color: #fff;
			font-size: 70px;
			font-weight: 400;
			line-height: 80%;
			margin: 0;
			@include respond-to('medium') {
				font-size: 50px;
			}
			@include respond-to('extra-small') {
				font-size: 40px;
			}
		}
	}
	&__subtitle {
		h2{
			text-align: $right;
			color: #fff;
			font-size: 50px;
			font-weight: 900;
			margin: 0;
			@include respond-to('medium') {
				font-size: 40px;
			}
			@include respond-to('extra-small') {
				font-size: 35px;
			}
		}
	}
}