.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 500px;
	@include respond-to('small') {
		min-height: 300px;
	}
}

.banner-top-content__image {
	position: relative;
	background-position: center center;
	background-size: cover;
	height: 500px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.5);
	}
	@include respond-to('small') {
		height: 300px;
	}
}